import React, { useState, useEffect } from "react";
import emailjs from "@emailjs/browser";
import Navbar from "../../component/Navbar/navbar";
import { Link } from "react-router-dom";
import { FiPhone } from "react-icons/fi";
import contact from "../../assets/images/contact.svg";
import { contactDataHome } from "../../data/data";
import image1 from "../../assets/images/vector01.png";
import image2 from "../../assets/images/programming/1.jpg";
import image3 from "../../assets/images/programming/2.jpg";
import aboutImg from "../../assets/images/programming/3.jpg";
import atlanticImgBig from "../../assets/images/atlantic_big.png";
import upgradeDigital from "../../assets/images/upgrade_digital.jpg";
import * as Icon from "react-feather";
import UserFeedBack from "../../component/userFeedBack";
import GetInTuch from "../../component/getInTuch";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { TypeAnimation } from "react-type-animation";
import CompanyLogo from "../../component/companyLogo";
import CountUp from "react-countup";
import {
  FiChevronDown,
  FiChevronRight,
  FiCode,
  FiHexagon,
} from "react-icons/fi";
import { MdOutlineCheckCircle } from "react-icons/md";
import { faqData, services, beneficiariosData } from "../../data/data-six";
import bg from "../../assets/images/programming/bg.jpg";
import Blog from "../../component/blog";
import Footer from "../../component/Footer/footer";
import CookieModal from "../../component/cookieModal";
import { faW } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

export default function LojaOnline() {
  let [isOpen, setOpen] = useState(false);
  let [activeTab, setActiveTab] = useState(1);

  useEffect(() => {
    const htmlTag = document.getElementsByTagName("html")[0];
    htmlTag.classList.add("dark");
    htmlTag.classList.remove("light");
  });

  const service_id = "service_j718rv4";
  const template_id = "template_24fyf1a";
  const public_key = "9cUzHp6SScF19_ARF";

  const [templateParams, setTemplateParams] = useState({
    from_name: "",
    to_name: "",
    message: "",
  });

  const handleInputChange = (e) => {
    setTemplateParams({
      ...templateParams,
      [e.target.name]: e.target.value,
    });
  };

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [errors, setErrors] = useState({});

  function sendEmail(e) {
    e.preventDefault();
    const newErrors = validate(templateParams);
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    setLoading(true);
    setErrors({});
    emailjs.send(service_id, template_id, templateParams, public_key).then(
      (result) => {
        console.log(result);
        setTemplateParams({
          from_name: "",
          to_name: "",
          message: "",
        });

        setLoading(false);
        setSuccess(true);
      },
      (error) => {
        console.log(error);
        setLoading(false);
        setError("Something went wrong. Please try again later.");
      }
    );
  }

  const validate = (params) => {
    let errors = {};

    if (!params.from_name) {
      errors.from_name = "Nome é obrigatório";
    }

    if (!params.to_name) {
      errors.to_name = "Email é obrigatório";
    } else if (!/\S+@\S+\.\S+/.test(params.to_name)) {
      errors.to_name = "Email é inválido";
    }

    if (!params.message) {
      errors.message = "Mensagem é obrigatória";
    }

    return errors;
  };

  return (
    <>
      <Navbar />

      <section className="relative table w-full lg:py-40 md:py-36 pt-36 pb-24 overflow-hidden bg-white dark:bg-slate-900">
        <span className="absolute blur-[200px] size-[600px] rounded-full top-1/2 -translate-y-1/2 start-1/2 ltr:-translate-x-1/2 rtl:translate-x-1/2 bg-indigo-600/50"></span>
        <div className="container relative z-1">
          <div className="grid md:grid-cols-2 items-center gap-[30px]">
            <div className="">
              <h4 className="font-bold lg:leading-normal leading-normal text-4xl lg:text-5xl mb-5 text-black dark:text-white relative">
                Olá! <br /> somos uma {" "}
                <span className="after:absolute after:end-0 after:start-0 after:bottom-4 after:lg:h-4 after:h-3 after:w-auto after:bg-indigo-600/30 relative text-indigo-600">
                  <TypeAnimation
                    sequence={[
                      // Same substring at the start will only be typed out once, initially
                      "Talentosa",
                      1000, // wait 1s before replacing "Mice" with "Hamsters"
                      "Creativa",
                      1000,
                      "Inovadora",
                      1000,
                    ]}
                    wrapper="span"
                    speed={10}
                    repeat={Infinity}
                    className="typewrite"
                    cursor={false}
                  ></TypeAnimation>
                </span>
                <br />empresa de programação.
              </h4>
            </div>
            <div className="relative">
              <div className="flex justify-center">
                <div className="relative overflow-hidden rounded-full md:w-3/4">
                  <img src={image1} alt="" />
                  <div className="absolute inset-0 bg-gradient-to-tl to-indigo-600/30 from-yellow-600/30"></div>
                </div>
              </div>

              <div className="absolute -top-8 start-0 mover z-10">
                <img src={atlanticImgBig} className="h-64" alt="" />
              </div>

              <div className="absolute -bottom-8 end-0 mover-2">
                <img src={image2} className="rounded-2xl" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-6 border-t border-b border-gray-100 dark:border-gray-700">
        <div className="container relative">
          <CompanyLogo />
        </div>
      </section>

      <section className="relative md:py-24 py-16 overflow-hidden">
        <div className="container relative ">
          <div className="grid grid-cols-1 pb-8 text-center">
            <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
              Lojas Online, até 70% de financiamento
            </h3>
            <p className="text-slate-400 max-w-xl mx-auto">
              Comece a vender online com a nossa ajuda. Oferecemos soluções
              completas para a criação de lojas de e-commerce. Aceite pagamentos
              online via MBWay, PayPal ou cartão de crédito.
            </p>
          </div>

          <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 mt-8 gap-[30px]">
            {services.map((item, index) => {
              let Icon = item.icon;
              return (
                <div
                  key={index}
                  className="group relative p-6 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 hover:bg-indigo-600 dark:hover:bg-indigo-600 duration-500 rounded-xl bg-white dark:bg-slate-900 overflow-hidden text-center"
                >
                  <div className="relative overflow-hidden text-transparent -m-3">
                    <FiHexagon className="size-24 fill-indigo-600/5 group-hover:fill-white/10 mx-auto"></FiHexagon>
                    <div className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-indigo-600 rounded-xl group-hover:text-white duration-500 text-3xl flex align-middle justify-center items-center">
                      <Icon className="" />
                    </div>
                  </div>

                  <div className="mt-6">
                    <Link
                      to="#"
                      className="text-lg font-medium group-hover:text-white duration-500"
                    >
                      {item.title}
                    </Link>
                    <p className="text-slate-400 group-hover:text-white/50 duration-500 mt-3">
                      {item.desc}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <section
        className="py-20 w-full table relative bg-fixed bg-center bg-no-repeat bg-cover"
        style={{ backgroundImage: `url(${bg})` }}
      >
        <div className="absolute inset-0 bg-gradient-to-tl to-indigo-600/75 from-yellow-600/75"></div>
        <div className="container relative">
          <div className="grid grid-cols-1 text-center">
            <h3 className="mb-4 md:text-4xl text-3xl text-white font-semibold">
              Upgrade Digital Empresarial
            </h3>

            <p className="text-white/70 max-w-xl mx-auto">
              Oferecemos apoio financeiro de até 70% para a construção da sua
              loja online. Aproveite esta oportunidade para expandir o seu
              negócio. Com taxas de financiamento que variam de 50% a 70%, você
              pode investir no futuro digital da sua empresa.
            </p>

            <div className="mt-8">
              <img src={upgradeDigital} alt="" />
            </div>
          </div>
        </div>
      </section>

      <section className="relative md:py-24 py-16">
        <div className="container relative">
          <div className="grid grid-cols-1 pb-8 text-center">
            <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
              Despesas Elegíveis
            </h3>

            <p className="text-slate-400 max-w-xl mx-auto">
              Descubra como o Upgrade Digital pode transformar o seu negócio com
              apoio financeiro de até 70% para a implementação de tecnologias
              digitais que melhoram a competitividade e a eficiência.
            </p>
          </div>

          <div
            id="accordion-collapse"
            data-accordion="collapse"
            className="grid md:grid-cols-2 grid-cols-1 mt-8 md:gap-[30px]"
          >
            <div>
              {faqData.slice(0, 4).map((item, index) => {
                return (
                  <div
                    className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mb-3"
                    key={index}
                  >
                    <h2 className="text-base font-semibold">
                      <button
                        type="button"
                        className={`flex justify-between items-center p-5 w-full font-medium text-start ${
                          activeTab === item.id
                            ? "bg-gray-50 dark:bg-slate-800 text-indigo-600"
                            : ""
                        }`}
                        onClick={() => setActiveTab(item.id)}
                      >
                        <span>{item.title}</span>
                        <FiChevronDown
                          className={`size-4 shrink-0 ${
                            activeTab === item.id ? "rotate-180" : ""
                          }`}
                        />
                      </button>
                    </h2>
                    <div className={activeTab === item.id ? "" : "hidden"}>
                      <div className="p-5">
                        <p className="text-slate-400 dark:text-gray-400">
                          {item.desc}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>

            <div>
              {faqData.slice(4, 8).map((item, index) => {
                return (
                  <div
                    className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mb-3"
                    key={index}
                  >
                    <h2 className="text-base font-semibold">
                      <button
                        type="button"
                        className={`flex justify-between items-center p-5 w-full font-medium text-start ${
                          activeTab === item.id
                            ? "bg-gray-50 dark:bg-slate-800 text-indigo-600"
                            : ""
                        }`}
                        onClick={() => setActiveTab(item.id)}
                      >
                        <span>{item.title}</span>
                        <FiChevronDown
                          className={`size-4 shrink-0 ${
                            activeTab === item.id ? "rotate-180" : ""
                          }`}
                        />
                      </button>
                    </h2>
                    <div className={activeTab === item.id ? "" : "hidden"}>
                      <div className="p-5">
                        <p className="text-slate-400 dark:text-gray-400">
                          {item.desc}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
      <div className="container relative">
        <div className="grid grid-cols-1 text-center">
          <h3 className="md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">
            Informações Importantes
          </h3>
        </div>
      </div>

      <section className="relative md:py-24 py-16">
        <div className="container relative ">
          <div className="grid md:grid-cols-2 grid-cols-1 gap-[30px]">
            <div className="flex">
              <Icon.HelpCircle className="fea icon-ex-md text-indigo-600 me-3"></Icon.HelpCircle>
              <div className="flex-1">
                <h5 className="mb-2 text-xl font-semibold">
                  Taxa de Financiamento
                </h5>
                <p className="text-slate-400">
                  A taxa de financiamento varia conforme a dimensão da empresa:
                  <ul className="list-disc ml-5">
                    <li>Micro e Pequena: 70%</li>
                    <li>Média: 60%</li>
                    <li>Grande: 50%</li>
                  </ul>
                </p>
              </div>
            </div>

            <div className="flex">
              <Icon.HelpCircle className="fea icon-ex-md text-indigo-600 me-3"></Icon.HelpCircle>
              <div className="flex-1">
                <h5 className="mb-2 text-xl font-semibold">Beneficiários</h5>
                <p className="text-slate-400">
                  Empresas elegíveis incluem:
                  <ul className="list-disc ml-5">
                    <li>
                      Independente da dimensão ou forma jurídica, ao abrigo do
                      RGIC.
                    </li>
                    <li>Com sede ou estabelecimento estável na RAA.</li>
                    <li>
                      Legalmente constituídas há pelo menos 6 meses na data de
                      submissão da candidatura.
                    </li>
                  </ul>
                </p>
              </div>
            </div>
          </div>
        </div>

        <section className="relative md:py-24 py-16">
          <div className="grid grid-cols-1 text-center">
            <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">
              Quer um orçamento gratuito para a sua loja online?
            </h3>
          </div>
          <div className="container relative">
            <div className="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-2 gap-[10px]">
              {contactDataHome.map((item, index) => {
                let Icons = item.icon;
                return (
                  <div key={index} className="text-center px-6 mt-6">
                    <div className="size-20 bg-indigo-600/5 text-indigo-600 rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                      <Icons className="size-7" />
                    </div>

                    <div className="content mt-7">
                      <h5 className="title h5 text-xl font-medium">
                        {item.title}
                      </h5>
                      <p className="text-slate-400 mt-3">{item.desc}</p>

                      <div className="mt-5">
                        <Link
                          to="#"
                          className="relative inline-block font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500"
                        >
                          {item.contact}
                        </Link>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="container relative md:mt-24 mt-16">
            <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
              <div className="lg:col-span-7 md:col-span-6">
                <img src={contact} alt="" />
              </div>

              <div className="lg:col-span-5 md:col-span-6">
                <div className="lg:ms-5">
                  <div className="bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-800 p-6">
                    <h3 className="mb-6 text-2xl leading-normal font-medium">
                      Peça um Orçamento Gratuito para a sua Loja Online
                    </h3>
                    {error ? (
                      <div className="text-center">
                        <h4 className="text-red-500">
                          Algo de errado aconteceu!
                        </h4>
                      </div>
                    ) : success ? (
                      <div className="text-center">
                        <h4 className="text-green-500">
                          Email enviado com sucesso!
                        </h4>
                      </div>
                    ) : (
                      <form>
                        <div className="grid lg:grid-cols-12 lg:gap-6">
                          <div className="lg:col-span-6 mb-5">
                            <div className="text-start">
                              <label htmlFor="name" className="font-semibold">
                                Nome:
                              </label>
                              <div className="form-icon relative mt-2">
                                <Icon.User className="size-4 absolute top-3 start-4"></Icon.User>
                                <input
                                  name="from_name"
                                  onChange={handleInputChange}
                                  value={templateParams.from_name}
                                  id="from_name"
                                  type="text"
                                  disabled={loading}
                                  className="form-input ps-11 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                                  placeholder="Nome"
                                />
                                {errors.from_name && (
                                  <p className="text-red-500">
                                    {errors.from_name}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="lg:col-span-6 mb-5">
                            <div className="text-start">
                              <label htmlFor="email" className="font-semibold">
                                Email:
                              </label>
                              <div className="form-icon relative mt-2">
                                <Icon.Mail className="size-4 absolute top-3 start-4"></Icon.Mail>
                                <input
                                  name="to_name"
                                  onChange={handleInputChange}
                                  value={templateParams.to_name}
                                  id="to_name"
                                  type="email"
                                  disabled={loading}
                                  required
                                  className="form-input ps-11 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                                  placeholder="Email"
                                />
                                {errors.to_name && (
                                  <p className="text-red-500">
                                    {errors.to_name}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="grid grid-cols-1">
                          <div className="mb-5">
                            <div className="text-start">
                              <label
                                htmlFor="comments"
                                className="font-semibold"
                              >
                                Mensagem:
                              </label>
                              <div className="form-icon relative mt-2">
                                <Icon.MessageCircle className="size-4 absolute top-3 start-4"></Icon.MessageCircle>
                                <textarea
                                  name="message"
                                  onChange={handleInputChange}
                                  value={templateParams.message}
                                  id="message"
                                  disabled={loading}
                                  className="form-input ps-11 w-full py-2 px-3 h-28 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                                  placeholder="Mensagem"
                                ></textarea>
                                {errors.message && (
                                  <p className="text-red-500">
                                    {errors.message}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <button
                          type="submit"
                          id="submit"
                          name="send"
                          onClick={sendEmail}
                          className="py-2 px-5 font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md justify-center flex items-center"
                        >
                          Enviar Mensagem
                          {loading && (
                            <svg
                              className="animate-spin h-5 w-5 ms-3"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                              ></circle>
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0c4.418 0 8 3.582 8 8h-2c0-3.314-2.686-6-6-6V0c-3.314 0-6 2.686-6 6H4z"
                              ></path>
                            </svg>
                          )}
                        </button>
                      </form>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <GetInTuch /> */}

        <a
          href="https://wa.me/+351914699791"
          style={{
            position: "fixed",
            right: "20px",
            bottom: "20px",
            backgroundColor: "#25D366",
            color: "white",
            borderRadius: "50%",
            padding: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: "1000",
          }}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faWhatsapp} size="2x" />
        </a>
      </section>

      <Footer />
    </>
  );
}
