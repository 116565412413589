import { FiCalendar, FiCodesandbox, FiDollarSign, FiShield, FiThumbsUp, FiUser } from "react-icons/fi";
import { LuAirplay, LuBell, LuCreditCard, LuDatabase, LuLineChart, LuOrbit, LuRocket, LuTruck, LuUser2 } from "react-icons/lu";
import { PiCrosshairBold } from "react-icons/pi";

import clener1 from '../assets/images/cleaner/1.jpg'
import clener2 from '../assets/images/cleaner/2.jpg'
import clener3 from '../assets/images/cleaner/3.jpg'
import clener4 from '../assets/images/cleaner/4.jpg'
import clener5 from '../assets/images/cleaner/5.jpg'
import clener6 from '../assets/images/cleaner/6.jpg'

import team1 from '../assets/images/client/04.jpg'
import team2 from '../assets/images/client/05.jpg'
import team3 from '../assets/images/client/06.jpg'
import team4 from '../assets/images/client/07.jpg'
import { BiMailSend } from "react-icons/bi";

export const webServices = [
    {
      icon: LuLineChart,
      title: 'Desenvolvimento de Software',
      desc: 'Soluções de software personalizadas para o seu negócio.'
    },
    {
      icon: PiCrosshairBold,
      title: 'Desenvolvimento de Aplicativos Móveis',
      desc: 'Aplicativos intuitivos para Android e iOS.'
    },
    {
      icon: LuAirplay,
      title: 'Desenvolvimento Web',
      desc: 'Websites modernos e responsivos.'
    },
    {
      icon: LuRocket,
      title: 'Automação de Processos',
      desc: 'Automatize tarefas para aumentar a eficiência.'
    }
  ]
  export const services = [
    {
      title: "Loja com Pagamentos Online",
      desc: "Oferecemos a criação de lojas online com integração de pagamentos via MBWay, cartão de crédito ou PayPal.",
      icon: LuCreditCard
    },
    {
      title: "Envio de Notificações",
      desc: "Implementamos sistemas de notificação para manter os seus clientes atualizados sobre o estado das suas encomendas.",
      icon: LuBell
    },
    {
      title: "Estado de Encomendas",
      desc: "Oferecemos soluções para o acompanhamento em tempo real do estado das encomendas.",
      icon: LuTruck
    },
    {
      title: "Automação e Aceleração de Vendas Online",
      desc: "Implementamos soluções de automação para acelerar o processo de vendas online e aumentar a eficiência do seu negócio.",
      icon: LuOrbit
    }
  ];
  
  
  export const beneficiariosData = [
    {
        id: 1,
        title: 'Empresas Elegíveis',
        desc: 'Empresas de qualquer dimensão ou forma jurídica que atendam aos critérios estabelecidos.'
    },
    {
        id: 2,
        title: 'Localização',
        desc: 'As empresas devem ter sede ou estabelecimento estável na Região Autónoma dos Açores (RAA).'
    },
    {
        id: 3,
        title: 'Tempo de Constituição',
        desc: 'As empresas devem estar legalmente constituídas há pelo menos 6 meses na data de submissão da candidatura.'
    }
];

export const faqData = [

    {
        id: 1,
        title: 'Equipamentos',
        desc: 'Vitrines digitais, máquinas e equipamentos informáticos e de IoT, equipamento de videoconferência, infraestruturas de redes, e equipamentos para automatização dos processos de pagamento.'
    },
    {
        id: 2,
        title: 'Software e Serviços Cloud',
        desc: 'Licenças de software (iniciais), subscrições de serviços SaaS e PaaS, desenvolvimento de software e aplicações à medida, e implementação de cibersegurança.'
    },
    {
        id: 3,
        title: 'Marketing Digital e Rebranding',
        desc: 'Desenvolvimento de rebranding, marketing digital, gestão da presença online, e adaptação da identidade visual para comunicação digital.'
    },
    {
        id: 4,
        title: 'Serviços de Consultoria',
        desc: 'Consultoria para a elaboração do plano de capacitação e transição digital da empresa, com um limite de 750€.'
    },
    {
        id: 5,
        title: 'Outras Despesas',
        desc: 'Elaboração, preparação e acompanhamento da candidatura, até 1.000€, e submissão de pedidos de pagamento, até 1.000€.'
    }
];



export const cleaningServices = [
    {
        id:1,
        icon:FiCodesandbox,
        title:'Latest Equipments',
        desc:'The phrasal sequence of the is now so that many campaign and benefit'
    },
    {
        id:2,
        icon:FiDollarSign,
        title:'Affordable Service',
        desc:'The phrasal sequence of the is now so that many campaign and benefit'
    },
    {
        id:3,
        icon:FiUser,
        title:'Expert Team',
        desc:'The phrasal sequence of the is now so that many campaign and benefit'
    },
    {
        id:4,
        icon:FiThumbsUp,
        title:'Quality Work',
        desc:'The phrasal sequence of the is now so that many campaign and benefit'
    },
]

export const cleanerCounter = [
    {
        title:'Satisfied Client',
        value:1548
    },
    {
        title:'Expert Team',
        value:25
    },
    {
        title:'Years Experience',
        value:9
    },
]

export const clenerServices = [
    {
        image:clener1,
        title:'House Cleaning',
        feature:['Vacuming','Bathroom Cleaning','Window Cleaning','Bedrooms Cleaning']
    },
    {
        image:clener2,
        title:'Office Cleaning',
        feature:['Vacuming','Bathroom Cleaning','Window Cleaning','Bedrooms Cleaning']
    },
    {
        image:clener3,
        title:'Kitchen Cleaning',
        feature:['Vacuming','Bathroom Cleaning','Window Cleaning','Bedrooms Cleaning']
    },
    {
        image:clener4,
        title:'Window Cleaning',
        feature:['Vacuming','Bathroom Cleaning','Window Cleaning','Bedrooms Cleaning']
    },
    {
        image:clener5,
        title:'Carpet Cleaning',
        feature:['Vacuming','Bathroom Cleaning','Window Cleaning','Bedrooms Cleaning']
    },
    {
        image:clener6,
        title:'Car Cleaning',
        feature:['Vacuming','Bathroom Cleaning','Window Cleaning','Bedrooms Cleaning']
    },
]

export const clenerTeam = [
    {
        image:team1,
        name:'Jack John',
        desc:'Cleaner'
    },
    {
        image:team2,
        name:'Krista John',
        desc:'Cleaner'
    },
    {
        image:team3,
        name:'Roger Jackson',
        desc:'Cleaner'
    },
    {
        image:team4,
        name:'Johnny English',
        desc:'Cleaner'
    },
]

export const clenerBlog = [
    {
        image:clener1,
        title:'Design your apps in your own way',
        desc:'The phrasal sequence of the is now so that many campaign and benefit'
    },
    {
        image:clener2,
        title:'How apps is changing the IT world',
        desc:'The phrasal sequence of the is now so that many campaign and benefit'
    },
    {
        image:clener3,
        title:'Smartest Applications for Business',
        desc:'The phrasal sequence of the is now so that many campaign and benefit'
    },
]

export const servicesSef = [
    {
      title: "Automação do Processo de Submissão",
      desc: "Automatizamos o processo de submissão para torná-lo mais eficiente e menos propenso a erros.",
      icon: LuOrbit
    },
    {
      title: "Pré-preenchimento de Dados pelo Hóspede",
      desc: "Permitimos que o hóspede preencha alguns dados, economizando tempo e melhorando a experiência do usuário.",
      icon: LuUser2
    },
    {
      title: "Registo de Dados na Plataforma",
      desc: "Os dados preenchidos pelo hóspede são registrados na plataforma para futuras referências e processamento.",
      icon: LuDatabase
    },
    {
      title: "Envio Fácil",
      desc: "Com os dados já registrados na plataforma, o envio é tão simples quanto clicar em um botão. Para o SEF e SREA.",
      icon: LuRocket
    }
  ];

export const servicesData = [
    {
        id:1,
        icon:FiShield,
        title:'Secured Payment',
        desc:'The phrasal sequence of the is now so that many campaign and benefit'
    },
    {
        id:2,
        icon:FiCalendar,
        title:'Easy to use',
        desc:'The phrasal sequence of the is now so that many campaign and benefit'
    },
    {
        id:3,
        icon:BiMailSend,
        title:'Support',
        desc:'The phrasal sequence of the is now so that many campaign and benefit'
    },
]

