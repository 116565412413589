import React from "react";
import { Link } from "react-router-dom";

import logo_light from "../../assets/images/logo-light.png";
import american_ex from "../../assets/images/payments/american-ex.png";
import discover from "../../assets/images/payments/discover.png";
import master_card from "../../assets/images/payments/master-card.png";
import paypal from "../../assets/images/payments/paypal.png";
import visa from "../../assets/images/payments/visa.png";

import * as Icon from "react-feather";

import { footerLinks, footerCompany } from "../../data/data";
import { PiShoppingCart } from "react-icons/pi";
import {
  FaBehance,
  FaDribbble,
  FaFacebookF,
  FaInstagram,
  FaLinkedin,
  FaPhone,
  FaRegEnvelope,
  FaRegFile,
  FaTwitter,
  FaWhatsapp,
} from "react-icons/fa";
import { MdKeyboardArrowRight } from "react-icons/md";
import { FaP } from "react-icons/fa6";

export default function Footer() {
  return (
    <footer className="footer bg-dark-footer relative text-gray-200 dark:text-gray-200">
      <div className="container relative">
        <div className="grid grid-cols-12">
          <div className="col-span-12">
            <div className="py-[60px] px-0">
              <div className="grid md:grid-cols-6 grid-cols-1 gap-[30px]">
                <div className="lg:col-span-2 md:col-span-12">
                  <Link to="/#" className="text-[22px] focus:outline-none">
                    <img src={logo_light} alt="" />
                  </Link>
                  <p className="mt-6 text-gray-300">
                    Oferecemos soluções completas de desenvolvimento de
                    software, websites e automação de processos para aumentar a
                    eficiência, reduzir erros e facilitar o crescimento do seu
                    negócio.
                  </p>
                  <ul className="list-none mt-5 space-x-1 space-y-1">
                    <li className="inline">
                      <Link
                        to="https://www.instagram.com/atlanticsolutions/"
                        target="_blank"
                        className="size-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"
                      >
                        <FaInstagram className="text-sm" />
                      </Link>
                    </li>
                  </ul>
                </div>

                <div className="lg:col-span-3 md:col-span-4">
                  <h5 className="tracking-[1px] text-gray-100 font-semibold">
                    Contactos
                  </h5>
                  <ul className="list-none mt-4 space-y-2">
                    <li className="flex items-center">
                      <FaRegEnvelope className="text-sm me-2" />
                      <Link to="mailto:geral@atlanticsolutions.pt">
                        <span>geral@atlanticsolutions.pt</span>
                      </Link>
                    </li>
                  </ul>
                  <ul className="list-none mt-4 space-y-2">
                    <li className="flex items-center">
                      <FaPhone className="text-sm me-2" />
                      <Link to="tel:+351 91 46 99 791">
                        <span>+351 91 46 99 791</span>
                      </Link>
                    </li>
                  </ul>
                  <ul className="list-none mt-4 space-y-2">
                    <li className="flex items-center">
                      <FaWhatsapp className="text-sm me-2" />
                      <Link to="https://wa.me/351914699791" target="_blank">
                        <span>+351 91 46 99 791</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="py-[30px] px-0 border-t border-slate-800">
        <div className="container relative text-center">
          <div className="grid md:grid-cols-2 items-center">
            <div className="md:text-start text-center">
              <p className="mb-0">
                © {new Date().getFullYear()} Todos os direitos reservados.
                Desenvolvido por{" "}
                <Link
                  to="https://atlanticsolutions.pt"
                  target="_blank"
                  className="text-indigo-600 hover:text-indigo-700"
                >
                  Altantic Solutions
                </Link>
              </p>
            </div>

            <ul className="list-none md:text-end text-center space-x-1 mt-6 md:mt-0">
              <li className="inline">
                <Link to="#">
                  <img
                    src={american_ex}
                    className="max-h-6 inline"
                    title="American Express"
                    alt=""
                  />
                </Link>
              </li>
              <li className="inline">
                <Link to="#">
                  <img
                    src={discover}
                    className="max-h-6 inline"
                    title="Discover"
                    alt=""
                  />
                </Link>
              </li>
              <li className="inline">
                <Link to="#">
                  <img
                    src={master_card}
                    className="max-h-6 inline"
                    title="Master Card"
                    alt=""
                  />
                </Link>
              </li>
              <li className="inline">
                <Link to="#">
                  <img
                    src={paypal}
                    className="max-h-6 inline"
                    title="Paypal"
                    alt=""
                  />
                </Link>
              </li>
              <li className="inline">
                <Link to="#">
                  <img
                    src={visa}
                    className="max-h-6 inline"
                    title="Visa"
                    alt=""
                  />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}
