import React from 'react'
import { useState } from 'react';
import emailjs from "@emailjs/browser";
import { Link } from 'react-router-dom';
import contact from '../../assets/images/contact.svg';
import Navbar from '../../component/Navbar/navbar'
import Footer from '../../component/Footer/footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

import * as Icon from 'react-feather';
import { contactDataHome } from '../../data/data';
import { MdKeyboardArrowRight } from 'react-icons/md';

export default function ContactOne() {

    const service_id = "service_j718rv4";
    const template_id = "template_24fyf1a";
    const public_key = "9cUzHp6SScF19_ARF";
  
    const [templateParams, setTemplateParams] = useState({
      from_name: "",
      to_name: "",
      message: "",
    });
  
    const handleInputChange = (e) => {
      setTemplateParams({
        ...templateParams,
        [e.target.name]: e.target.value,
      });
    };
  
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(null);
    const [errors, setErrors] = useState({});
  
    function sendEmail(e) {
      e.preventDefault();
      const newErrors = validate(templateParams);
      if (Object.keys(newErrors).length > 0) {
        setErrors(newErrors);
        return;
      }
      setLoading(true);
      setErrors({});
      emailjs.send(service_id, template_id, templateParams, public_key).then(
        (result) => {
          console.log(result);
          setTemplateParams({
            from_name: "",
            to_name: "",
            message: "",
          });
  
          setLoading(false);
          setSuccess(true);
        },
        (error) => {
          console.log(error);
          setLoading(false);
          setError("Something went wrong. Please try again later.");
        }
      );
    }
  
    const validate = (params) => {
      let errors = {};
  
      if (!params.from_name) {
        errors.from_name = "Nome é obrigatório";
      }
  
      if (!params.to_name) {
        errors.to_name = "Email é obrigatório";
      } else if (!/\S+@\S+\.\S+/.test(params.to_name)) {
        errors.to_name = "Email é inválido";
      }
  
      if (!params.message) {
        errors.message = "Mensagem é obrigatória";
      }
  
      return errors;
    };
    
    return (
        <>
            <Navbar navClass="nav-light" />

            <section className="relative table w-full py-36 bg-[url('../../assets/images/company/aboutus.jpg')] bg-center bg-no-repeat bg-cover">
                <div className="absolute inset-0 bg-black opacity-75"></div>
                <div className="container relative">
                    <div className="grid grid-cols-1 pb-8 text-center mt-10">
                        <h3 className="md:text-4xl text-3xl md:leading-normal tracking-wide leading-normal font-medium text-white">Contactos</h3>
                    </div>
                </div>

                <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                    <ul className="tracking-[0.5px] mb-0 inline-flex space-x-1">
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/index">Atlantic Solutions</Link></li>
                        <li className="inline-block text-base text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180"><MdKeyboardArrowRight className="text-xl"/></li>
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white" aria-current="page">Contactos</li>
                    </ul>
                </div>
            </section>
            <div className="relative">
                <div className="shape absolute sm:-bottom-px -bottom-[2px] start-0 end-0 overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto scale-[2.0] origin-top" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            <section className="relative md:py-24 py-16">
          <div className="grid grid-cols-1 text-center">
            <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">
              Tem interesse em saber mais sobre o Upgrade Digital Empresarial?
            </h3>
          </div>
          <div className="container relative">
            <div className="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-2 gap-[10px]">
              {contactDataHome.map((item, index) => {
                let Icons = item.icon;
                return (
                  <div key={index} className="text-center px-6 mt-6">
                    <div className="size-20 bg-indigo-600/5 text-indigo-600 rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                      <Icons className="size-7" />
                    </div>

                    <div className="content mt-7">
                      <h5 className="title h5 text-xl font-medium">
                        {item.title}
                      </h5>
                      <p className="text-slate-400 mt-3">{item.desc}</p>

                      <div className="mt-5">
                        <Link
                          to="/tel:+351 91 46 99 791"
                          className="relative inline-block font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500"
                        >
                          {item.contact}
                        </Link>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="container relative md:mt-24 mt-16">
            <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
              <div className="lg:col-span-7 md:col-span-6">
                <img src={contact} alt="" />
              </div>

              <div className="lg:col-span-5 md:col-span-6">
                <div className="lg:ms-5">
                  <div className="bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-800 p-6">
                    <h3 className="mb-6 text-2xl leading-normal font-medium">
                      Poderá entrar em contacto connosco através do formulário
                    </h3>
                    {error ? (
                      <div className="text-center">
                        <h4 className="text-red-500">Algo de errado aconteceu!</h4>
                      </div>
                    ) : success ? (
                      <div className="text-center">
                        <h4 className="text-green-500">
                          Email enviado com sucesso!
                        </h4>
                      </div>
                    ) : (
                      <form>
                        <div className="grid lg:grid-cols-12 lg:gap-6">
                          <div className="lg:col-span-6 mb-5">
                            <div className="text-start">
                              <label htmlFor="name" className="font-semibold">
                                Nome:
                              </label>
                              <div className="form-icon relative mt-2">
                                <Icon.User className="size-4 absolute top-3 start-4"></Icon.User>
                                <input
                                  name="from_name"
                                  onChange={handleInputChange}
                                  value={templateParams.from_name}
                                  id="from_name"
                                  type="text"
                                  disabled={loading}
                                  className="form-input ps-11 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                                  placeholder="Nome"
                                />
                                {errors.from_name && <p className="text-red-500">{errors.from_name}</p>}
                              </div>
                            </div>
                          </div>

                          <div className="lg:col-span-6 mb-5">
                            <div className="text-start">
                              <label htmlFor="email" className="font-semibold">
                                Email:
                              </label>
                              <div className="form-icon relative mt-2">
                                <Icon.Mail className="size-4 absolute top-3 start-4"></Icon.Mail>
                                <input
                                  name="to_name"
                                  onChange={handleInputChange}
                                  value={templateParams.to_name}
                                  id="to_name"
                                  type="email"
                                  disabled={loading}
                                  required
                                  className="form-input ps-11 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                                  placeholder="Email"
                                />
                                {errors.to_name && <p className="text-red-500">{errors.to_name}</p>}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="grid grid-cols-1">
                          <div className="mb-5">
                            <div className="text-start">
                              <label
                                htmlFor="comments"
                                className="font-semibold"
                              >
                                Mensagem:
                              </label>
                              <div className="form-icon relative mt-2">
                                <Icon.MessageCircle className="size-4 absolute top-3 start-4"></Icon.MessageCircle>
                                <textarea
                                  name="message"
                                  onChange={handleInputChange}
                                  value={templateParams.message}
                                  id="message"
                                  disabled={loading}
                                  className="form-input ps-11 w-full py-2 px-3 h-28 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                                  placeholder="Mensagem"
                                ></textarea>
                                {errors.message && <p className="text-red-500">{errors.message}</p>}
                              </div>
                            </div>
                          </div>
                        </div>
                        <button
                          type="submit"
                          id="submit"
                          name="send"
                          onClick={sendEmail}
                          className="py-2 px-5 font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md justify-center flex items-center"
                        >
                          Enviar Mensagem
                          {loading && (
                            <svg
                              className="animate-spin h-5 w-5 ms-3"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                              ></circle>
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0c4.418 0 8 3.582 8 8h-2c0-3.314-2.686-6-6-6V0c-3.314 0-6 2.686-6 6H4z"
                              ></path>
                            </svg>
                          )}
                        </button>
                      </form>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
            <div className="container-fluid relative">
                <div className="grid grid-cols-1">
                    <div className="w-full leading-[0] border-0">
                        <iframe  title='google' src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d1633.6407916555033!2d-25.523029248747456!3d37.820344794699906!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1spt-PT!2spt!4v1728240051991!5m2!1spt-PT!2spt" style={{border:0}} className="w-full h-[500px]" allowFullScreen></iframe>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
