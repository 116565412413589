import React from "react";
import logo_light from "../../assets/images/logo-light2.png";
import "../../assets/libs/@mdi/font/css/materialdesignicons.min.css";
import "../../assets/css/tailwind.css";
import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import * as Icon from "react-feather";

export default function Navbar(props) {
  let { navClass, navJustify } = props;
  let [isMenu, setisMenu] = useState(false);
  let [manu, setManu] = useState("");
  let location = useLocation();

  useEffect(() => {
    let current = location.pathname.substring(
      location.pathname.lastIndexOf("/") + 1
    );
    setManu(current);

    function windowScroll() {
      const navbar = document.getElementById("topnav");
      if (
        document.body.scrollTop >= 50 ||
        document.documentElement.scrollTop >= 50
      ) {
        if (navbar !== null) {
          navbar?.classList.add("nav-sticky");
        }
      } else {
        if (navbar !== null) {
          navbar?.classList.remove("nav-sticky");
        }
      }
    }
    window.addEventListener("scroll", windowScroll);
    window.scrollTo(0, 0);
    return () => {
      window.removeEventListener("scroll", windowScroll);
    };
  }, [location.pathname.substring(location.pathname.lastIndexOf("/") + 1)]);

  const toggleMenu = () => {
    setisMenu(!isMenu);
    if (document.getElementById("navigation")) {
      const anchorArray = Array.from(
        document.getElementById("navigation").getElementsByTagName("a")
      );
      anchorArray.forEach((element) => {
        element.addEventListener("click", (elem) => {
          const target = elem.target.getAttribute("href");
          if (target !== "") {
            if (elem.target.nextElementSibling) {
              var submenu = elem.target.nextElementSibling.nextElementSibling;
              submenu.classList.toggle("open");
            }
          }
        });
      });
    }
  };

  return (
    <nav
      id="topnav"
      className={`defaultscroll ${
        navClass === "nav-light"
          ? ""
          : navClass === "nav-sticky"
          ? "bg-white dark:bg-slate-900"
          : ""
      }`}
    >
      <div className="container relative">
        {navClass === "nav-light" ? (
          <Link className="logo" to="/">
            <span className="inline-block dark:hidden">
              <img src={logo_light} className="l-light" height="24" alt="" />
            </span>
            <img
              src={logo_light}
              height="24"
              className="hidden dark:inline-block"
              alt=""
            />
          </Link>
        ) : (
          <Link className="logo" to="/">
            <img src={logo_light} className="hidden dark:inline-block" alt="" />
          </Link>
        )}

        <div className="menu-extras">
          <div className="menu-item">
            <Link
              to="#"
              className={`navbar-toggle ${isMenu ? "open" : ""}`}
              id="isToggle"
              onClick={() => toggleMenu()}
            >
              <div className="lines">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </Link>
          </div>
        </div>

        <div id="navigation" style={{ display: isMenu ? "block" : "none" }}>
          <ul className={`navigation-menu ${navClass} ${navJustify}`}>
            <li className={manu === "index" || "" ? "active" : ""}>
              <Link to="/" className="sub-menu-item">
                Home
              </Link>
            </li>

            {/*<li className={`${["index-saas", "index-classic-saas","index-modern-saas", "index-apps","index-classic-app","index-ai","index-smartwatch","index-marketing","index-seo","index-software","index-web-programming","index-payment","index-charity","index-it-solution","index-it-solution-two","index-digital-agency","index-restaurent","index-hosting","index-nft","index-hotel","index-travel","index-cafe","index-gym","index-yoga","index-spa","index-cleaner","index-job","index-startup","index-business","index-corporate","index-corporate-two","index-real-estate","index-consulting","index-insurance","index-construction","index-law","index-video","index-personal","index-portfolio","index-photography","index-studio","index-coworking","index-course","index-event","index-podcast","index-hospital","index-phone-number","index-forums","index-shop","index-crypto","index-landing-one","index-landing-two","index-landing-three","index-landing-four","index-service","index-food-blog","index-blog","index-furniture","index-landing-five","index-life-coach","index-landing-six"].includes(manu)? "active" : ""} has-submenu parent-parent-menu-item`} >
                        <Link to="#" >Landings</Link><span className="menu-arrow"></span>

                        <ul className="submenu megamenu">
                            <li>
                                <ul>
                                    <li className={manu === "index-saas" || "" ? "active" : ""}><Link to="/index-saas" className="sub-menu-item">Saas</Link></li>
                                    <li className={manu === "index-classic-saas" || "" ? "active" : ""}><Link to="/index-classic-saas" className="sub-menu-item">Classic Saas </Link></li>
                                    <li className={manu === "index-modern-saas" || "" ? "active" : ""}><Link to="/index-modern-saas" className="sub-menu-item">Modern Saas </Link></li>
                                    <li className={manu === "index-apps" || "" ? "active" : ""}><Link to="/index-apps" className="sub-menu-item">Application</Link></li>
                                    <li className={manu === "index-classic-app" || "" ? "active" : ""}><Link to="/index-classic-app" className="sub-menu-item">Classic App </Link></li>
                                    <li className={manu === "index-ai" || "" ? "active" : ""}><Link to="/index-ai" className="sub-menu-item">AI Tools<span className="bg-black dark:bg-slate-50 inline-block text-white dark:text-slate-900 text-[10px] font-bold px-2.5 py-0.5 rounded h-5 ms-1">Dark</span></Link></li>
                                    <li className={manu === "index-smartwatch" || "" ? "active" : ""}><Link to="/index-smartwatch" className="sub-menu-item">Smartwatch</Link></li>
                                    <li className={manu === "index-marketing" || "" ? "active" : ""}><Link to="/index-marketing" className="sub-menu-item">Marketing</Link></li>
                                    <li className={manu === "index-seo" || "" ? "active" : ""}><Link to="/index-seo" className="sub-menu-item">SEO Agency </Link></li>
                                    <li className={manu === "index-software" || "" ? "active" : ""}><Link to="/index-software" className="sub-menu-item">Software </Link></li>
                                    <li className={manu === "index-web-programming" || "" ? "active" : ""}><Link to="/index-web-programming" className="sub-menu-item">Web Programming <span className="bg-yellow-500 inline-block text-white text-[10px] font-bold px-2.5 py-0.5 rounded h-5 ms-1">New</span></Link></li>
                                    <li className={manu === "index-payment" || "" ? "active" : ""}><Link to="/index-payment" className="sub-menu-item">Payments</Link></li>
                                    <li className={manu === "index-charity" || "" ? "active" : ""}><Link to="/index-charity" className="sub-menu-item">Charity </Link></li>
                                </ul>
                            </li>

                            <li>
                                <ul>
                                    <li className={manu === "index-it-solution" || "" ? "active" : ""}><Link to="/index-it-solution" className="sub-menu-item">IT Solution</Link></li>
                                    <li className={manu === "index-it-solution-two" || "" ? "active" : ""}><Link to="/index-it-solution-two" className="sub-menu-item">It Solution Two </Link></li>
                                    <li className={manu === "index-digital-agency" || "" ? "active" : ""}><Link to="/index-digital-agency" className="sub-menu-item">Digital Agency</Link></li>
                                    <li className={manu === "index-restaurent" || "" ? "active" : ""}><Link to="/index-restaurent" className="sub-menu-item">Restaurent</Link></li>
                                    <li className={manu === "index-hosting" || "" ? "active" : ""}><Link to="/index-hosting" className="sub-menu-item">Hosting</Link></li>
                                    <li className={manu === "index-nft" || "" ? "active" : ""}><Link to="/index-nft" className="sub-menu-item">NFT Marketplace <span className="bg-red-500 inline-block text-white text-[10px] font-bold px-2.5 py-0.5 rounded h-5 ms-1">Full</span></Link></li>
                                    <li className={manu === "index-hotel" || "" ? "active" : ""}><Link to="/index-hotel" className="sub-menu-item">Hotel & Resort</Link></li>
                                    <li className={manu === "index-travel" || "" ? "active" : ""}><Link to="/index-travel" className="sub-menu-item">Travels</Link></li>
                                    <li className={manu === "index-cafe" || "" ? "active" : ""}><Link to="/index-cafe" className="sub-menu-item">Cafe <span className="bg-black dark:bg-slate-50 inline-block text-white dark:text-slate-900 text-[10px] font-bold px-2.5 py-0.5 rounded h-5 ms-1">Dark</span></Link></li>
                                    <li className={manu === "index-gym" || "" ? "active" : ""}><Link to="/index-gym" className="sub-menu-item">Gym <span className="bg-black dark:bg-slate-50 inline-block text-white dark:text-slate-900 text-[10px] font-bold px-2.5 py-0.5 rounded h-5 ms-1">Dark</span></Link></li>
                                    <li className={manu === "index-yoga" || "" ? "active" : ""}><Link to="/index-yoga" className="sub-menu-item">Yoga </Link></li>
                                    <li className={manu === "index-spa" || "" ? "active" : ""}><Link to="/index-spa" className="sub-menu-item">Spa & Salon </Link></li>
                                    <li className={manu === "index-cleaner" || "" ? "active" : ""}><Link to="/index-cleaner" className="sub-menu-item">Cleaner Service <span className="bg-yellow-500 inline-block text-white text-[10px] font-bold px-2.5 py-0.5 rounded h-5 ms-1">New</span></Link></li>
                                </ul>
                            </li>

                            <li>
                                <ul>
                                    <li className={manu === "index-job" || "" ? "active" : ""}><Link to="/index-job" className="sub-menu-item">Job<span className="bg-red-500 inline-block text-white text-[10px] font-bold px-2.5 py-0.5 rounded h-5 ms-1">Full</span></Link></li>
                                    <li className={manu === "index-startup" || "" ? "active" : ""}><Link to="/index-startup" className="sub-menu-item">Startup</Link></li>
                                    <li className={manu === "index-business" || "" ? "active" : ""}><Link to="/index-business" className="sub-menu-item">Business</Link></li>
                                    <li className={manu === "index-corporate" || "" ? "active" : ""}><Link to="/index-corporate" className="sub-menu-item">Corporate</Link></li>
                                    <li className={manu === "index-corporate-two" || "" ? "active" : ""}><Link to="/index-corporate-two" className="sub-menu-item">Corporate Two </Link></li>
                                    <li className={manu === "index-real-estate" || "" ? "active" : ""}><Link to="/index-real-estate" className="sub-menu-item">Real Estate <span className="bg-red-500 inline-block text-white text-[10px] font-bold px-2.5 py-0.5 rounded h-5 ms-1">Full</span></Link></li>
                                    <li className={manu === "index-consulting" || "" ? "active" : ""}><Link to="/index-consulting" className="sub-menu-item">Consulting </Link></li>
                                    <li className={manu === "index-life-coach" || "" ? "active" : ""}><Link to="/index-life-coach" className="sub-menu-item">Life Coach </Link></li>
                                    <li className={manu === "index-insurance" || "" ? "active" : ""}><Link to="/index-insurance" className="sub-menu-item">Insurance </Link></li>
                                    <li className={manu === "index-construction" || "" ? "active" : ""}><Link to="/index-construction" className="sub-menu-item">Construction </Link></li>
                                    <li className={manu === "index-law" || "" ? "active" : ""}><Link to="/index-law" className="sub-menu-item">Law Firm </Link></li>
                                    <li className={manu === "index-video" || "" ? "active" : ""}><Link to="/index-video" className="sub-menu-item">Video </Link></li>
                                    <li className={manu === "index-christmas" || "" ? "active" : ""}><Link to="/index-christmas" className="sub-menu-item">Christmas </Link></li>
                                </ul>
                            </li>

                            <li>
                                <ul>
                                    <li className={manu === "index-personal" || "" ? "active" : ""}><Link to="/index-personal" className="sub-menu-item">Personal</Link></li>
                                    <li className={manu === "index-portfolio" || "" ? "active" : ""}><Link to="/index-portfolio" className="sub-menu-item">Portfolio <span className="bg-red-500 inline-block text-white text-[10px] font-bold px-2.5 py-0.5 rounded h-5 ms-1">Full</span></Link></li>
                                    <li className={manu === "index-photography" || "" ? "active" : ""}><Link to="/index-photography" className="sub-menu-item">Photography <span className="bg-black dark:bg-slate-50 inline-block text-white dark:text-slate-900 text-[10px] font-bold px-2.5 py-0.5 rounded h-5 ms-1">Dark</span></Link></li>
                                    <li className={manu === "index-studio" || "" ? "active" : ""}><Link to="/index-studio" className="sub-menu-item">Studio</Link></li>
                                    <li className={manu === "index-coworking" || "" ? "active" : ""}><Link to="/index-coworking" className="sub-menu-item">Co-Woriking</Link></li>
                                    <li className={manu === "index-classic-business" || "" ? "active" : ""}><Link to="/index-classic-business" className="sub-menu-item">Classic Business </Link></li>
                                    <li className={manu === "index-course" || "" ? "active" : ""}><Link to="/index-course" className="sub-menu-item">Online Courses </Link></li>
                                    <li className={manu === "index-event" || "" ? "active" : ""}><Link to="/index-event" className="sub-menu-item">Event / Conference </Link></li>
                                    <li className={manu === "index-podcast" || "" ? "active" : ""}><Link to="/index-podcast" className="sub-menu-item">Podcasts</Link></li>
                                    <li className={manu === "index-hospital" || "" ? "active" : ""}><Link to="/index-hospital" className="sub-menu-item">Hospital</Link></li>
                                    <li className={manu === "index-phone-number" || "" ? "active" : ""}><Link to="/index-phone-number" className="sub-menu-item">Phone Number</Link></li>
                                    <li className={manu === "index-forums" || "" ? "active" : ""}><Link to="/index-forums" className="sub-menu-item">Forums </Link></li>
                                </ul>
                            </li>
                       

                            <li>
                                <ul>
                                    <li className={manu === "index-shop" || "" ? "active" : ""}><Link to="/index-shop" className="sub-menu-item">Shop / eCommerce <span className="bg-red-500 inline-block text-white text-[10px] font-bold px-2.5 py-0.5 rounded h-5 ms-1">Full</span> </Link></li>
                                    <li className={manu === "index-crypto" || "" ? "active" : ""}><Link to="/index-crypto" className="sub-menu-item">Cryptocurrency  <span className="bg-black dark:bg-slate-50 inline-block text-white dark:text-slate-900 text-[10px] font-bold px-2.5 py-0.5 rounded h-5 ms-1">Dark</span></Link></li>
                                    <li className={manu === "index-landing-one" || "" ? "active" : ""}><Link to="/index-landing-one" className="sub-menu-item">Landing One</Link></li>
                                    <li className={manu === "index-landing-two" || "" ? "active" : ""}><Link to="/index-landing-two" className="sub-menu-item">Landing Two</Link></li>
                                    <li className={manu === "index-landing-three" || "" ? "active" : ""}><Link to="/index-landing-three" className="sub-menu-item">Landing Three</Link></li>
                                    <li className={manu === "index-landing-four" || "" ? "active" : ""}><Link to="/index-landing-four" className="sub-menu-item">Landing Four</Link></li>
                                    <li className={manu === "index-landing-six" || "" ? "active" : ""}><Link to="/index-landing-six" className="sub-menu-item">Landing Six </Link></li>
                                    <li className={manu === "index-service" || "" ? "active" : ""}><Link to="/index-service" className="sub-menu-item">Service Provider</Link></li>
                                    <li className={manu === "index-food-blog" || "" ? "active" : ""}><Link to="/index-food-blog" className="sub-menu-item">Food Blog </Link></li>
                                    <li className={manu === "index-blog" || "" ? "active" : ""}><Link to="/index-blog" className="sub-menu-item">Blog </Link></li>
                                    <li className={manu === "index-furniture" || "" ? "active" : ""}><Link to="/index-furniture" className="sub-menu-item">Furniture </Link></li>
                                    <li className={manu === "index-landing-five" || "" ? "active" : ""}><Link to="/index-landing-five" className="sub-menu-item">Landing Five <span className="bg-green-600 inline-block text-white text-[10px] font-bold px-2.5 py-0.5 rounded h-5 ms-1">Onepage</span></Link></li>
                                </ul>
                            </li>
                    

                        </ul>
                    </li>
                    */}

            {/* <li className={`${["portfolio-modern-two", "portfolio-modern-three","portfolio-modern-four","portfolio-modern-five","portfolio-modern-six","portfolio-classic-two","portfolio-classic-three","portfolio-classic-four","portfolio-classic-five","portfolio-classic-six","portfolio-creative-two","portfolio-creative-three","portfolio-creative-four","portfolio-creative-five","portfolio-creative-six","portfolio-masonry-two","portfolio-masonry-three","portfolio-masonry-four","portfolio-masonry-five","portfolio-masonry-six","portfolio-detail-one","portfolio-detail-two","portfolio-detail-three",'portfolio-detail-four'].includes(manu)? "active" : ""} has-submenu parent-parent-menu-item`} >
                        <Link to="/portfolio-modern-two">Portfolio</Link>
                    </li>
                    */}

            <li className={manu === "contacto" || "" ? "active" : ""}>
              <Link to="/loja-online" className="sub-menu-item">
                Loja Online
              </Link>
            </li>
            <li className={manu === "contacto" || "" ? "active" : ""}>
              <Link to="/submissao-boletins" className="sub-menu-item">
                Submissão de Boletim
              </Link>
            </li>

            <li className={manu === "contacto" || "" ? "active" : ""}>
              <Link to="/contacto" className="sub-menu-item">
                Contactos
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
