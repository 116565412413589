import React from "react";
import { FiPhone } from "react-icons/fi";
import { Link } from "react-router-dom";

export default function GetInTuch({ title }) {
  return (
    <div className="container relative md:mt-24 mt-16 ">
      <div className="grid grid-cols-1 text-center">
        <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">
          Tem interesse em saber mais sobre o Upgrade Digital Empresarial?
        </h3>

        <p className="text-slate-400 max-w-xl mx-auto">
          Fazemos orçamentos gratuitos e oferecemos apoio completo na submissão
          das candidaturas, tudo chave na mão. Basta entrar em contacto
          connosco!
        </p>

        <div className="mt-6">
          <Link
            to="/contacto"
            className="py-2 px-5 inline-flex font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md mt-4"
          >
            <FiPhone className="me-1 text-lg" /> Contact us
          </Link>
        </div>
      </div>
    </div>
  );
}
